import React from "react";

function SkillsSection({ data }) {
  const skill = data;
  return (
    <section class="mb-4 col-break-avoid">
      <header>
        <h3 class="text-md font-semibold text-gray-900 leading-snugish">
          {skill.name}
        </h3>
        <p class="leading-normal text-md text-gray-700">Nivå: {skill.level}</p>
      </header>

      <ul class="py-1.5 mb-6 flex flex-wrap text-sm leading-snugish">
        {skill.keywords.map((data, index) => {
          return (
            <li
              className="px-2 py-1 rounded-sm mr-2 mt-2 text-gray-700 bg-yellow-400"
              index={index}
            >
              {data}
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default SkillsSection;
