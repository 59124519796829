module.exports = {
  basics: {
    name: `Olle Burlin`,
    label: `Frontend-utvecklare`,
    picture: ``,
    email: `mail@olleburlin.se`,
    phone: `+46 768051375`,
    website: `https://olleburlin.se`,
    summary: `Webbdesigner och utvecklare`,
    location: {
      title: `Studio 73A`,
      address: `Jörgen Kocksgatan 73A`,
      postalCode: `211 20`,
      city: `Malmö`,
      countryCode: `SE`,
      region: ``,
    },
    profiles: [
      {
        network: `Instagram`,
        username: `olleburlin`,
        url: `https://instagram.com/olleburlin`,
      },
    ],
  },
  about: [
    {
      title: `Frontend-utvecklare`,
      startDate: `2006`,
      endDate: ``,
      summary: `Jag har byggt hemsidor sedan mitten av nittiotalet men på en mer professionell nivå sedan 2006. Senast kommer jag från musikbranschen där jag jobbat på ett skivbolag/management och ansvarat för alla artisters hemsidor och digitala marknasföring. Jag använder de senaste teknologierna för att säkerställa att upplevelsen blir blixtsnabb, pålitlig och responsiv.`,
    },
    {
      title: `Wordpress-utvecklare`,
      startDate: `2008`,
      endDate: ``,
      summary: `Jag har gjort hemsidor från grunden med hjälp av Wordpress i över 12 år. Jag föredrar att göra mina teman från grunden för maximal anpassning av önskemål. Idag använder jag Wordpress både fullt ut och som "Headless CMS."`,
    },
  ],
  work: [
    {
      company: `Frilans`,
      position: `Webbdesigner, utvecklare och grafisk designer.`,
      website: ``,
      startDate: `2020`,
      endDate: ``,
      summary: `För tillfället är jag frilansande Webbdesigner, utvecklare och grafisk designer. Jag gör backend/API, frontend, design och även innehåll.`,
      highlights: [`Started the company`],
    },
    {
      company: `Bad Taste Empire`,
      position: `Creative Director, Utvecklare, Grafisk Designer, Content creator, marknadsföring`,
      website: `http://company.com`,
      startDate: `2006`,
      endDate: `2019`,
      summary: `På skivbolaget/managementet Bad Taste Empire hade jag överseende över artisternas kreativa uttryck, företagets profil och uttrycksmässiga strategi. Jag gjorde alla hemsidor, grafiskt material utifrån original, marknadsföring och innehåll.`,
      highlights: [`Started the company`],
    },
  ],
  volunteer: [
    {
      organization: `Organization`,
      position: `Volunteer`,
      website: `http://organization.com/`,
      startDate: `2012-01-01`,
      endDate: `2013-01-01`,
      summary: `Description...`,
      highlights: [`Awarded 'Volunteer of the Month'`],
    },
  ],
  education: [
    {
      institution: `University`,
      area: `Software Development`,
      studyType: `Bachelor`,
      startDate: `2011-01-01`,
      endDate: `2013-01-01`,
      gpa: `4.0`,
      courses: [`DB1101 - Basic SQL`],
    },
  ],

  skills: [
    {
      name: `HTML`,
      level: `Avancerad`,
      keywords: [`HTML5`, `Semantic`, `Clean`],
    },
    {
      name: `CSS`,
      level: `Avancerad`,
      keywords: [`CSS3`, `SASS`, `LESS`, "Bootstrap", "Tailwind"],
    },
    {
      name: `JavaScript`,
      level: `Medel`,
      keywords: [`React`, `Node`, `Gatsby`, `Next`, `ES6/ES7`],
    },
  ],
  extraSkills: [
    {
      name: `Programvara`,

      keywords: [
        `Illustrator`,
        `Photoshop`,
        `Premiere Pro`,
        `After Effects`,
        `Indesign`,
      ],
    },
  ],
  languages: [
    {
      language: `English`,
      fluency: `Native speaker`,
    },
  ],
  interests: [
    {
      name: `Wildlife`,
      keywords: [`Ferrets`, `Unicorns`],
    },
  ],
  references: [
    {
      name: `Jane Doe`,
      reference: `Reference...`,
    },
  ],
};
