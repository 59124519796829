import React from "react";

function AboutMeSection({ data }) {
  const about = data;
  return (
    <section class="mb-4 col-break-avoid">
      <header>
        <h3 class="text-md font-semibold text-gray-900 leading-snugish">
          {about.title}
        </h3>

        <p class="leading-normal text-md text-gray-600">
          {about.endDate ? `` : `Sedan `}
          {about.startDate}
          {about.endDate ? `-` : ``}
          {about.endDate}
        </p>
      </header>
      <p class="mt-2 text-md text-gray-900 leading-normal">{about.summary}</p>
    </section>
  );
}

export default AboutMeSection;
