import React from "react";

import resumeData from "@data/resumeDataSv.js";

import AboutMeSection from "@components/resume/AboutMeSection";
import SkillsSection from "@components/resume/SkillsSection";
import ExtraSkillsSection from "@components/resume/ExtraSkillsSection";
import ExperienceSection from "@components/resume/ExperienceSection";

import profilePhoto from "@images/olle.jpg";

function ResumeComp() {
  return (
    <div className="resume max-w-4xl w-full">
      <div class="p-6 mx-auto bg-white">
        <header className="mt-4 mb-10">
          <div className="flex flex-row items-center">
            <div className="w-2/3">
              <h1 className="text-4xl mb-2 text-left font-bold leading-none">
                {resumeData.basics.name}
              </h1>
              <span className="text-gray-700 leading-snug text-xl">
                {resumeData.basics.summary}
              </span>
            </div>
            <div className="w-1/3 ">
              <img
                alt="Profile"
                className="mx-auto rounded-full w-48"
                src={profilePhoto}
              />
            </div>
          </div>
        </header>
        {/* Content */}
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-3 sm:col-span-2">
            <section>
              <div class="col-break-avoid">
                <h2 class="mb-4 font-bold text-lg text-gray-500 uppercase">
                  Vad jag gör
                </h2>
                {resumeData.about.map((data, index) => {
                  return <AboutMeSection data={data} index={index} />;
                })}
              </div>
            </section>
            <section class="mt-8">
              <h2 class="mb-4 font-bold text-lg text-gray-500 uppercase">
                Erfarenhet
              </h2>
              {resumeData.work.map((data, index) => {
                return <ExperienceSection data={data} index={index} />;
              })}
            </section>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <section className="">
              <h2 class="mb-4 font-bold text-lg text-gray-500 uppercase">
                Färdigheter
              </h2>

              {resumeData.skills.map((data, index) => {
                return <SkillsSection data={data} index={index} />;
              })}

              {resumeData.extraSkills.map((data, index) => {
                return <ExtraSkillsSection data={data} index={index} />;
              })}
            </section>
            <section class="mt-8">
              <div class="col-break-avoid">
                <h2 class="mb-4 font-bold text-lg text-gray-500 uppercase">
                  Kontakt
                </h2>

                <section class="mb-4 col-break-avoid">
                  <ul>
                    <li class="mt-1.5 leading-normal text-gray-700 pl-7 text-md">
                      {resumeData.basics.name}
                    </li>

                    <li class="mt-1.5 leading-normal text-gray-700 pl-7 text-md">
                      {resumeData.basics.location.title}
                      <br />
                      {resumeData.basics.location.address}
                      <br />
                      {resumeData.basics.location.postalCode}
                      &nbsp;
                      {resumeData.basics.location.city}
                    </li>
                    <li class="mt-1.5 leading-normal text-gray-700 pl-7 text-md">
                      {resumeData.basics.email}
                    </li>
                    <li class="mt-1.5 leading-normal text-gray-700 pl-7 text-md">
                      {resumeData.basics.phone}
                    </li>
                  </ul>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResumeComp;
