import React from "react";

function ExperienceSection({ data }) {
  const work = data;
  return (
    <section className="mb-4 col-break-avoid">
      <header>
        <h3 className="text-lg font-semibold text-gray-900 leading-snugish">
          {work.company}
        </h3>
        <p className="leading-normal text-md text-gray-600">
          {work.endDate ? `` : `Sedan `}
          {work.startDate}
          {work.endDate ? `-` : ``}
          {work.endDate}
          {``}|{work.position}
        </p>
      </header>
      <p className="mt-2 text-md text-gray-900 leading-normal">
        {work.summary}
      </p>
    </section>
  );
}

export default ExperienceSection;
