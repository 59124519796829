import React from "react";
import Fade from "react-reveal/Fade";
import LayoutPage from "../components/LayoutPage";
import SEO from "../components/seo";
import Resume from "@components/resume/Resume";

function ResumePage() {
  return (
    <LayoutPage>
      <SEO
        keywords={[
          `Olle`,
          `Burlin`,
          `Web designer`,
          `Webbdesigner`,
          `React`,
          `ReactJs`,
          `Wordpress`,
        ]}
        title="Resumé - Olle Burlin"
      />
      <Fade>
        <section className="flex flex-col md:flex-row items-center">
          <Resume />
        </section>
      </Fade>
    </LayoutPage>
  );
}

export default ResumePage;
